import {
    createSlice,
    createAsyncThunk,
    PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
    getAccountBalance, getAccountPaymentBtnFlag
} from 'services/RequestDataService';
import { AccountBalance, AccountPaymentBtnFlag } from 'data/CustomerPortalTypes';

export const fetchAccountBalance = createAsyncThunk(
    'accountBalances/fetchAccountBalance',
    async (accountId: number) => {
        return await getAccountBalance(accountId);
    });

export const fetchAccountPaymentBtnFlag = createAsyncThunk(
    'accountPaymentBtnFlags/fetchAccountPaymentBtnFlag',
    async (accountId: number) => {
        return await getAccountPaymentBtnFlag(accountId);
    });

type TransactionState = {
    accountBalances: AccountBalance[],
    accountPaymentBtnFlags: AccountPaymentBtnFlag[],
}

const initialState: TransactionState = {
    accountBalances: [],
    accountPaymentBtnFlags: [],
}

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: initialState,
    reducers: {
        updateAccountBalance(state, action: PayloadAction<AccountBalance>) {
            if (!action?.payload?.accountId
                || (action.payload.balance !== 0
                    && !action.payload.balance)) {
                return;
            }

            state.accountBalances = [
                ...state
                    .accountBalances
                    .filter(b => b.accountId !==
                        action.payload.accountId),
                {
                    accountId: action.payload.accountId,
                    balance: action.payload.balance,
                    maxRefundableAmount: action.payload.maxRefundableAmount
                }
            ];
        },
        updateAccountPaymentBtnFlag(state, action: PayloadAction<AccountPaymentBtnFlag>) {
            if (!action?.payload?.accountId) {
                return;
            }

            state.accountPaymentBtnFlags = [
                ...state
                    .accountPaymentBtnFlags
                    .filter(b => b.accountId !==
                        action.payload.accountId),
                {
                    accountId: action.payload.accountId,
                    paymentBtnFlag: action.payload.paymentBtnFlag,
                }
            ];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAccountBalance.fulfilled, (state, action) => {
            const { meta, payload } = action || {};
            const { success, data } = payload || {};
            
            if (!success || !data || !meta) {
                return;
            }

            const { balance, maxRefundableAmount } = data;
            const accountId = meta.arg;

            state.accountBalances = [
                        ...state.accountBalances.filter(b => b.accountId !== accountId),
                        { accountId, balance, maxRefundableAmount }
            ];
        })

        builder.addCase(fetchAccountPaymentBtnFlag.fulfilled, (state, action) => {
            if (action?.payload?.success !== true) {
                return;
            }

            state.accountPaymentBtnFlags = [
                ...state
                    .accountPaymentBtnFlags
                    .filter(b => b.accountId !==
                        action.meta.arg),
                { accountId: action.meta.arg, paymentBtnFlag: action.payload!.data! }
            ];
        })
    },
});

export default transactionSlice.reducer;

export const getBalanceByAccountId = (accountId?: number | null) =>
    (state: RootState) => accountId
        ? state.transation
            .accountBalances
            .find(ab => ab.accountId === accountId)
            ?.balance ?? null
        : null;

export const getMaxRefundableAmountByAccountId = (accountId?: number | null) =>
    (state: RootState) => accountId
        ? state.transation
            .accountBalances
            .find(ab => ab.accountId === accountId)
            ?.maxRefundableAmount
        : null;

export const getPaymentBtnFlagByAccountId = (accountId?: number | null) =>
    (state: RootState) => accountId
        ? state.transation
            .accountPaymentBtnFlags
            .find(ab => ab.accountId === accountId)
            ?.paymentBtnFlag ?? null
        : null;

export const { updateAccountBalance, updateAccountPaymentBtnFlag } = transactionSlice.actions;