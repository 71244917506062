const links = {
    forms: {
        VIC: {
            electricityAbolishmentForm: "https://www.globirdenergy.com.au/shared-assets/pdf/abolishment-of-electricity-supply.pdf",
            ewrForm: "https://www.globirdenergy.com.au/shared-assets/pdf/Blank-EWR.pdf",
            lifeSupportForm: "https://www.globirdenergy.com.au/shared-assets/pdf/LifeSupportForm-20193112-v04b.pdf",
            lifeSupportConcessionForm: "https://www.globirdenergy.com.au/shared-assets/pdf/Life-Support-Concession_Application_Form.pdf",
            medicalCoolingConcessionForm: "https://www.globirdenergy.com.au/shared-assets/pdf/Medical-Cooling-Concession-Form.pdf"
        },
        NSW: {
            lifeSupportRebateForm: "https://www.globirdenergy.com.au/shared-assets/pdf/NSW-Medical-Energy-rebate.pdf",
            medicalEnergyRebateForm: "https://www.globirdenergy.com.au/shared-assets/pdf/NSW-Medical-Energy-rebate.pdf",

        },
        SA: {
            lifeSupportNotificationForm: "https://www.globirdenergy.com.au/shared-assets/pdf/SA-Life-Support_Equipment.pdf"
        },
        QLD: {
            energexLifeSupportForm: "https://www.globirdenergy.com.au/shared-assets/pdf/Energex-Medical-Notification-of-Life-Support-Equipment-Form-8048.pdf",
            ergonLifeSupportForm: "https://www.globirdenergy.com.au/shared-assets/pdf/Ergon-Life-Support-Medical-Notification.pdf"
        },
        ALL: {
            gasApplicationForm: "https://www.globirdenergy.com.au/shared-assets/pdf/GloBird-Gas-Application-form-06.pdf",
            directDebitForm: "https://www.globirdenergy.com.au/wp-content/uploads/securepdfs/2023/12/2023-DD-form-digital-New.pdf",
            jointOwnerApplicationForm: "https://www.globirdenergy.com.au/wp-content/uploads/securepdfs/2023/12/2023-GloBird-Joint-Account-Owner.pdf",
        }
    },
    terms: {
        directDebitTerms: "https://globirdcdn.azureedge.net/Globird_Direct_Debit_Term_And_Conditions.pdf"
    },
    corporateWebsite: {
        privacyPolicy: "https://www.globirdenergy.com.au/legal/privacy/",
        legal: "https://www.globirdenergy.com.au/legal/",
        myAccountFaqSection: "https://www.globirdenergy.com.au/help-support/faq/#MyAccount",
        referral: "https://www.globirdenergy.com.au/refer/",
        tariffTimetables: "https://www.globirdenergy.com.au/legal/flexible/",
        estimation: "https://www.globirdenergy.com.au/estimatebill/",
        howToReadMeterDetails: "https://www.globirdenergy.com.au/how-do-i-read-my-meter/#reading-your-meter",
        howToReadMeterPage: "https://www.globirdenergy.com.au/how-do-i-read-my-meter/",
        fees: "https://www.globirdenergy.com.au/fees/",
        legalFees: "https://www.globirdenergy.com.au/legal/fees/",
        movingHome: "https://www.globirdenergy.com.au/moving-home/",
        landingPage: "https://www.globirdenergy.com.au/",
        concessions: "https://www.globirdenergy.com.au/concessions/",
        contactUs: "https://www.globirdenergy.com.au/contact-us/",
        myFirstBill: "https://www.globirdenergy.com.au/my-first-bill/",
        understandingYourBill: "https://www.globirdenergy.com.au/understanding-your-bill/",
        myMonthlyBill: "https://www.globirdenergy.com.au/my-monthly-bill/"
    },
    oss: {
        landingPage: "https://quote.globirdenergy.com.au/"
    },
    concession: {
        saConcessionInfo: "https://www.sa.gov.au/topics/care-and-support/concessions/household-concessions/energy-bill-concessions"
    },
    email: {
        cs: "cs@globirdenergy.com.au"
    },
    phone: {
        general: "tel:+61133456",
        generalDisplay: "13 3456",
        international: "tel:+61388138899",
        internationalDisplay: "03 8813 8899",
        chinese: "tel:+611300516888",
        chineseDisplay: "1300 516 888",
        interpreter: "tel:+61131450",
        interpreterDisplay: "13 1450"
    }
};

export default links;