import menuMyDetails from 'assets/menu-mydetails.svg';
import menuPlan from 'assets/menu-plan.svg';
import menuBill from 'assets/menu-bill.svg';
import menuUsage from 'assets/menu-usage.svg';
import menuSupport from 'assets/menu-support.svg';
import dashRefer from 'assets/dash-refer.png';
import garyImage from 'assets/gary.png';
import refer from 'assets/refer.svg';
import {InfoCircleFilled} from '@ant-design/icons';
import requestDataService from 'services/RequestDataService';
import {DataCache, formatAmount, formatDisplayDate, isMobileAndTablet} from 'utilities/Utility';
import {useNavigate} from 'react-router-dom';
import {selectCurrentAccount, selectUser} from 'store/userSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';
import classNames from 'classnames';
import {DashboardInfo} from 'data/CustomerPortalTypes';
import {
    fetchAccountBalance,
    fetchAccountPaymentBtnFlag,
    getBalanceByAccountId,
    getMaxRefundableAmountByAccountId,
    getPaymentBtnFlagByAccountId,
} from 'store/transactionSlice';
import styles from './Dashboard.module.css';
import {GlobirdTableContainer} from 'data/GlobirdTableContainer';
import GlobirdTable from 'components/Shared/GlobirdTable';
import {fetchDirectDebitInfo, getCurrentAccountDirectDebitInfo} from 'store/directDebitInfoSlice';
import Bwtt from 'components/Shared/ButtonWithToolTip';
import {fetchReconnectionInfo, selectCurrentAccountReconnectionInfo} from 'store/reconnectionInfoSlice';
import {Tooltip} from "antd";
import links from 'data/Links';
import SignupRoadmap from './SignupRoadmap/SignupRoadmap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import useElementsOnScreen from 'hooks/useElementsOnScreen';

type Tile = {
    menuId: string,
    menuTitle: string,
    menuIcon: string,
    menuContent: string,
    menuChildren: TileChild[],
    containerExtraClassnames?: string,
    itemExtraClassnames?: string
    disabled?: boolean
}

type TileChild = {
    path: string,
    label: string,
    id: string,
    tooltip?: string
}

type TileState = 'in' | 'out';
let tileState: Record<string, TileState> = {};
let lastTriggered: Record<string, number> = {};
let cbPending = false;

const debounceTileState = (
    menu: string,
    animateState: TileState,
    cb: (state: Record<string, TileState>) => void) => {

    if (tileState[menu] === animateState) {
        return;
    }

    if (animateState === 'in') {
        Object.keys(tileState)
            .forEach((k) => tileState[k] = 'out');
    }

    tileState[menu] = animateState;

    if (animateState === 'out') {
        cb({...tileState});

        return;
    }

    const current = +new Date();

    lastTriggered[menu] ??= current - 1000;

    if (current - lastTriggered[menu] > 500) {

        cbPending = false;

        lastTriggered[menu] = current;

        cb({...tileState});

        return;
    }

    if (cbPending) {
        return;
    }

    cbPending = true;

    setTimeout(() => {

        if (!cbPending) {
            return;
        }

        lastTriggered[menu] = +new Date();

        cb({...tileState});

        cbPending = false;
    }, 500 - (current - lastTriggered[menu]));
}

const defaultMenuTiles: Tile[] = [
    {
        menuId: 'myDetailsBlock',
        menuTitle: 'My Details',
        menuIcon: menuMyDetails,
        menuContent: '<p>Things like your name and address.<br />Expand to see options</p>',
        menuChildren: [
            {
                path: '/accountsummary',
                label: 'Account summary and correspondence method',
                id: "accountSummary"
            }, {
                path: '/directdebit',
                label: 'Set up / update direct debit',
                id: "directDebit"
            }, {
                path: '/accountholder',
                label: 'Add / update authorized contacts',
                id: 'accountHolder',
                tooltip: 'Authorised contacts can make enquiries & changes to the account but are not named on the bill or financially responsible. Be mindful of who you authorise.'
            }, {
                path: '/jointowner',
                label: 'Add / update joint account owner',
                id: 'jointOwner',
                tooltip: 'Joint Account Owner can make enquiries & changes to the account, are named on the bill, and are financially responsible. Signature of all parties required.'
            }, {
                path: '/changepassword',
                label: 'Change my password',
                id: 'changePassword'
            },
            {
                path: '/changeemail',
                label: 'Change login Email',
                id: 'changeEmail'
            },
            {
                path: '/concession',
                label: 'Manage concession',
                id: 'concession'
            },
        ]
    },
    {
        menuId: 'myPropertyBlock',
        menuTitle: 'My Property',
        menuIcon: menuPlan,
        menuContent: '<p>Arrange move out.<br />Expand to see options</p>',
        menuChildren: [
            {
                path: '/moveout',
                label: 'Move out',
                id: 'moveOut'
            },
            // {
            //     path: '/site',
            //     label: 'My meter & site'
            // }
        ]
    },
    {
        menuId: 'myBillBlock',
        menuTitle: 'My Bill',
        menuIcon: menuBill,
        menuContent: '<p>Things like your latest bill, previous bills and payment history.<br />Expand to see options</p>',
        menuChildren: [
            {
                path: '/paybill',
                label: 'Pay my bill',
                id: 'payBill'
            }, {
                path: '/billinghistory',
                label: 'Previous bills',
                id: 'billingHistory'
            }, {
                path: '/paymenthistory',
                label: 'Payment history',
                id: 'paymentHistory'
            }
        ]
    },
    {
        menuId: 'myUsageBlock',
        menuTitle: 'My Energy Usage',
        menuIcon: menuUsage,
        menuContent: '<p>Things like Usage targets, charts and alerts.<br />Expand to see options</p>',
        menuChildren: [
            {
                path: '/usagechart',
                label: 'Usage chart',
                id: 'usageChart'
            },
            {
                path: '/selfread',
                label: 'Upload self read',
                id: 'selfRead'
            },
            // {
            //     path: '/uploadmeterread',
            //     label: 'Upload a meter read'
            // }, {
            //     path: '/meterread',
            //     label: 'Previous meter read'
            // }
        ]
    },
    {
        menuId: 'referAFriend',
        menuTitle: 'Refer-A-Friend',
        menuIcon: refer,
        menuContent: '<p> Check your Power Perks at a glance, Send referral links and more</p>',
        menuChildren: [
            {
                path: '/linkmanagement',
                label:  'Referral link management',
                id: 'linkManagement'
            }
        ]
    },
    {
        menuId: 'mySupportBlock',
        menuTitle: 'My Support',
        menuIcon: menuSupport,
        menuContent: '<p>Ask us a question, book a call, FAQs.<br />Expand to see options</p>',
        menuChildren: [
            {
                path: '/faq',
                label: 'FAQs',
                id: 'faq'
            }, {
                path: '/assistance',
                label: 'Contact us',
                id: 'assistance'
            }, {
                path: '/selfserveform',
                label: 'Self Serve Forms',
                id: 'selfServeForm'
            }, {
                path: '/callback',
                label: 'Request a call-back',
                id: 'callback'
            }
        ]
    }
];

const dataCache: Record<number, DataCache<DashboardInfo>> = {};

export const Dashboard = () => {
    const navigate = useNavigate();
    const currentAccount = useSelector(selectCurrentAccount);
    const user = useSelector(selectUser);
    const europaAccountExists = (user && user.accounts.length > 0) || false;
    const [expandState, setExpandState] = useState<Record<string, TileState>>({});
    const [dashboardInfo, setDashboardInfo] = useState<DashboardInfo | null>(null);
    const accountBalance = useSelector(getBalanceByAccountId(currentAccount?.accountId));
    const maxRefundBalance = useSelector(getMaxRefundableAmountByAccountId(currentAccount?.accountId)) ?? 0;
    const directDebitInfo = useSelector(getCurrentAccountDirectDebitInfo());
    const paymentBtnDisabledFlag = useSelector(getPaymentBtnFlagByAccountId(currentAccount?.accountId));
    const currentAccountReconnectionInfo = useSelector(selectCurrentAccountReconnectionInfo);
    const accountCreditTooltipText = `Your account is in credit, of which $${formatAmount(Math.abs(maxRefundBalance).toString())} is refundable. The credit will remain in your account toward future bills. Alternatively, contact us to request a refund. Note that we will only refund overpayments back to the source of payment.`;

    const dispatch = useDispatch();

    useEffect(() => () => {
        tileState = {};
    }, []);

    const tableDataContainer = useMemo(() =>
        new GlobirdTableContainer(
            [
                'Last Invoice Number',
                <span>Due Date</span>,
                'Outstanding Balance'],
            [
                [
                    currentAccount ?
                        <a target="_blank"
                        rel="noreferrer"
                        href={`/api/correspondence/document?accountId=${currentAccount?.accountId}&documentId=${dashboardInfo?.lastestInvoice?.documentId}`}
                        >
                            {dashboardInfo?.lastestInvoice?.invoiceNumber?.toString()}
                        </a> 
                        : dashboardInfo?.lastestInvoice?.invoiceNumber?.toString(),
                    formatDisplayDate(dashboardInfo?.lastestInvoice?.dueDate),
                    accountBalance === null
                        ? ''
                        : (
                            <span>
                                {`$${formatAmount((Math.abs(accountBalance)).toString())}`}
                                {accountBalance > 0 &&(
                                    <>
                                        {' credit'}
                                        {maxRefundBalance > 0 &&(
                                        <Tooltip title={accountCreditTooltipText} color="#2e2e2d"
                                                 overlayInnerStyle={{borderRadius: "10px"}}>
                                            <InfoCircleFilled style={{marginLeft: '5px'}}/>
                                        </Tooltip>)}
                                    </>
                                )}
                            </span>
                        )
                ]
            ]
        ), [dashboardInfo, accountBalance, currentAccount]);

    const updateMenu = useCallback((
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        menu: string,
        animateState: TileState) => {

        // this is a very interesting issue, when we view dashboard page on mobile, there is no mouse movement to trigger mouseenter event
        // and click action will also trigger mouseenter event so redirect will be triggered when expanding menu
        // remove the if statement below and try to click menu on mobile view can trigger the issue

        if ((isMobileAndTablet() && event.type === 'click') || (!isMobileAndTablet() && event.type === 'mouseenter')
            || event.type === 'mouseleave') {

            debounceTileState(
                menu,
                animateState,
                setExpandState);
        }
    }, []);

    useEffect(() => {

        if (!currentAccount) {
            return;
        }

        (async () => {
            dataCache[currentAccount.accountId] ??=
                await DataCache.Create<DashboardInfo>(
                    async () => {

                        const result = (await requestDataService.getDashboardData(
                            currentAccount.accountId))
                            ?.data;

                        return result;
                    },
                    300000);

            dataCache[currentAccount.accountId]?.data
            && setDashboardInfo(dataCache[currentAccount.accountId].data!);

            dispatch(fetchAccountBalance(currentAccount.accountId));
        })();

    }, [dispatch, currentAccount]);

    useEffect(() => {

        if (!currentAccount || directDebitInfo !== undefined) {
            return;
        }

        dispatch(fetchDirectDebitInfo(currentAccount.accountId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentAccount]);

    useEffect(() => {
        if (!currentAccount || paymentBtnDisabledFlag != null) {
            return;
        }

        dispatch(fetchAccountPaymentBtnFlag(currentAccount.accountId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentAccount]);

    useEffect(() => {
        if (!currentAccount || currentAccountReconnectionInfo !== undefined) {
            return;
        }

        // fetch new flag if account changed
        dispatch(fetchReconnectionInfo(currentAccount.services.map(x => x.accountServiceId)));
    }, [dispatch, currentAccount]);

    const [menuContainerRef, isMenuVisible] = useElementsOnScreen({ threshold: 0.25 });

    const menuTiles: Tile[] | undefined = useMemo(() => {
        if (!user) {
            return;
        }

        const newMenuTiles: Tile[] = [...defaultMenuTiles.map(t => ({...t, disabled: !europaAccountExists}))];

        const accntMenuTile = newMenuTiles.find(t => t.menuId === 'myDetailsBlock');
        const accntMenuChild = accntMenuTile!.menuChildren;

        accntMenuTile!.itemExtraClassnames = styles['menu-item-long'];
        
        if (user.hasUnlinkedAccount
            && accntMenuChild
            && !accntMenuChild.find(c => c.path === '/accountlink')) {

            accntMenuChild.push(
                {
                    path: '/accountlink',
                    label: 'Link GloBird account',
                    id: 'accountLink'
                });
        } else if (!user.hasUnlinkedAccount
            && accntMenuChild
            && accntMenuChild.find(c => c.path === '/accountlink')) {

            accntMenuTile!.menuChildren = accntMenuChild.filter(c => c.path !== '/accountlink');
            accntMenuTile!.itemExtraClassnames = undefined;
        }


        const ddMenuChild = newMenuTiles
            .find(t => t.menuId === 'myDetailsBlock')!
            .menuChildren
            .find(c => c.path === '/directdebit')!;

        if (directDebitInfo) { // existing DD
            ddMenuChild.label = 'Update direct debit';
        } else { // no DD
            ddMenuChild.label = 'Set up direct debit';
        }

        // Electricity reconnection
        const propertyMenuTile = newMenuTiles.find(t => t.menuId === 'myPropertyBlock');
        const propertyMenuChild = propertyMenuTile!.menuChildren;
        if (currentAccountReconnectionInfo?.displayReconnectionPage
            && propertyMenuChild
            && !propertyMenuChild.find((c) => c.path === "/reconnection")) {

            propertyMenuChild.push(
                {
                    path: '/reconnection',
                    label: 'Electricity Reconnection',
                    id: 'reconnection'
                })
        } else if (!currentAccountReconnectionInfo?.displayReconnectionPage
            && propertyMenuChild
            && propertyMenuChild.find(c => c.path === '/reconnection')) {
            propertyMenuTile!.menuChildren = propertyMenuChild.filter(c => c.path !== '/reconnection');
        }

        if (!europaAccountExists) {
            // remove support menu with all the links available
            newMenuTiles.pop();
            
            // add support menu with only faq link
            newMenuTiles.unshift({
                menuId: 'mySupportBlock',
                menuTitle: 'My Support',
                menuIcon: menuSupport,
                menuContent: '<p>Ask us a question, book a call, FAQs.<br />Expand to see options</p>',
                menuChildren: [
                    {
                        path: '/faq',
                        label: 'FAQs',
                        id: 'faq'
                    }
                ],
                disabled: false
            });

            newMenuTiles.unshift({
                menuId: 'transferMovein',
                menuTitle: 'Transfer/Movein',
                menuIcon: menuPlan,
                menuContent: '<p>Your application is in progress!</p>',
                menuChildren: [],
                disabled: false
            });
        }

        return [
            ...newMenuTiles,
            ...Array(newMenuTiles.length % 3)
                .fill(1)
                .map(_ => ({
                    menuId: '',
                    menuTitle: '',
                    menuIcon: '',
                    menuContent: '',
                    menuChildren: [],
                    containerExtraClassnames: styles['blank-holder-tile']
                }))
        ];

    }, [user, directDebitInfo, currentAccountReconnectionInfo]);

    // disabled payment btn
    const paymentBtn = (paymentBtnDisabledFlag || paymentBtnDisabledFlag == null) ?
        <Bwtt
            disabled={true}
            disableMessage={`We have detected unusual payment activity on your account. To help us protect your account, please call us on ${links.phone.generalDisplay} to process the payment`}
            componentClassName={[classnames(styles['dashboard-item-button'])]}
            btnNode="Make a payment"
        />
        :
        <button
            id={"makePayment"}
            className={classnames('globird-button-primary', styles['dashboard-item-button'])}
            onClick={() => navigate("/paybill")}
        >
            Make a payment
        </button>;

    return (<div>
        {dashboardInfo?.lastestInvoice?.invoiceNumber ? null :
            <div className={classNames(styles['scroll-hint'], isMenuVisible ? styles['hide-scroll-hint'] : null)}>
                <FontAwesomeIcon icon={faArrowDown} />
            </div>}
        {europaAccountExists ? <div className={styles['dashboard-block']}>
            <div className={styles['dashboard-title']}>
                <p>Hi {dashboardInfo?.accountName}. Here's a snapshot of your account.</p>
            </div>
            <div className={styles['dashboard-content']}>
                <div className="globird-row">
                    <div className={classnames('globird-col', styles['dashboard-item'], styles['left-dashboard-item'])}>
                        <div>
                            <span className="globird-badge badge-yellow">LATEST CORRESPONDENCE</span>
                            <h2 style={{
                                marginTop: '35px',
                                wordBreak: 'keep-all'
                            }}>{dashboardInfo?.lastestCorrespondence?.title}</h2>
                            <button className={classnames('globird-button-primary', styles['dashboard-item-button'])}
                                    onClick={() => {
                                        navigate("/correspondences")
                                    }}>View correspondence
                            </button>
                        </div>
                        <hr/>
                        <div className={styles['dashboard-seperator']}/>
                    </div>
                    <div className={classnames('globird-col', styles['dashboard-item'])}>
                        <div>
                            <span className="globird-badge badge-green">ACCOUNT BALANCE</span>
                            <GlobirdTable dataContainer={tableDataContainer}></GlobirdTable>
                            {paymentBtn}
                            <button className={classnames('globird-button-primary', styles['dashboard-item-button'])}
                                    style={{right: 0}} onClick={() => {
                                navigate("/directdebit")
                            }}>{directDebitInfo ? 'Update ' : 'Set up '}direct debit
                            </button>
                        </div>
                        <hr/>
                        <div className={styles['dashboard-seperator']}/>
                    </div>
                    <div
                        className={classnames('globird-col', styles['dashboard-item'], styles['right-dashboard-item'])}>
                        <div>
                            <a href="/linkmanagement">
                                <img src={dashRefer} className={styles['dashboard-refer']} alt="refer"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> : (<div className={styles['dashboard-block']}>
            <div className={styles['concession-superman']}>
                <img src={garyImage} alt='Gary'/>
            </div>
            <div className={`${styles['dashboard-title']} ${styles['no-account']}`}>
                <p>Welcome, here's a snapshot of your account.</p>
            </div>
        </div>)}
        {dashboardInfo?.lastestInvoice?.invoiceNumber ? null : <SignupRoadmap />}
        <div className={styles['menu-block']} ref={menuContainerRef}>
            <div className="flex-between-top flex-wrap">
                {menuTiles && menuTiles.map((menu, i) => {
                        const {
                            menuTitle,
                            menuIcon,
                            menuContent,
                            menuId,
                            menuChildren,
                            containerExtraClassnames,
                            disabled,
                            itemExtraClassnames
                        } = menu;

                        return (
                            <div className={classNames(styles['menu-item-container'], containerExtraClassnames)}
                                 key={`dashboard-menu-item-${i}`}>
                                {
                                    !!menuId
                                    && <div
                                        className={classnames(
                                            {
                                                [styles['menu-item']]: true,
                                                [styles['menu-item-active']]: expandState[menuId] === 'in',
                                                [styles['menu-item-shrink']]: expandState[menuId] === 'out',
                                            },
                                            itemExtraClassnames)}
                                        onClick={(event) => !disabled && menuChildren.length && updateMenu(event, menuId, 'in')}
                                        onMouseEnter={(event) => !disabled && menuChildren.length && updateMenu(event, menuId, 'in')}
                                        onMouseLeave={(event) => !disabled && menuChildren.length && updateMenu(event, menuId, 'out')}>
                                        <div className={classnames(
                                            {
                                                [styles['menu-item-title-active']]: expandState[menuId] === 'in',
                                                [styles['menu-item-title']]: expandState[menuId] !== 'in',
                                                [styles['menu-item-title-disabled']]: disabled == true,
                                            })}>
                                            <a href={`#${menuId}`}>{menuTitle}</a>
                                        </div>
                                        {expandState[menuId] === 'in' ?
                                            <div className={styles['menu-item-content']}>
                                                <div>
                                                    {menuChildren.map((child, j) =>
                                                        <p onClick={() => child.path
                                                            && navigate(child.path)}
                                                            id={child.id}
                                                            key={`{dashboard-menu-item-${i}-line-${j}}`}>
                                                            {child.label} 
                                                            {child.tooltip &&
                                                            <Tooltip
                                                                title={child.tooltip}
                                                                color="#2e2e2d" overlayInnerStyle={{borderRadius: "10px"}}>
                                                                <InfoCircleFilled style={{ marginLeft: '5px' }}/>
                                                            </Tooltip>}
                                                        </p>
                                                    )}
                                                </div>
                                            </div> :
                                            <div className={styles['menu-item-content']}>
                                                <div className={styles['menu-item-icon']}>
                                                    <img src={menuIcon} alt="meun-item-icon-usage"/>
                                                </div>
                                                {disabled ? <div className={styles['disabled-menu-overlay']}>
                                                    <Tooltip
                                                        title="Access this menu upon completion of your account switch"
                                                        color="#2e2e2d" overlayInnerStyle={{borderRadius: "10px"}}>
                                                        <InfoCircleFilled style={{color: "#ec008c", fontSize: "40px"}}/>
                                                    </Tooltip>
                                                </div> : null}
                                                <div dangerouslySetInnerHTML={{__html: menuContent}}></div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>);
                    }
                )}
            </div>
        </div>
    </div>);
}

export default Dashboard;

export {dataCache as DashboardDataCache}