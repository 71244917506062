import { CustomUploadFile } from 'components/Shared/CustomImageUpload';
import { Moment } from 'moment';

class User {
    emailAddress: string = '';
    accounts: Account[] = [];
    currentAccount: Account | null = null;
    hasUnlinkedAccount: boolean = false;
    isImpersonatedUser: boolean = false;
};

type Account = {
    accountId: number,
    accountNumber: string,
    accountAddress: string,
    services: AccountServiceInfo[]
};

enum StateCode {
    VIC = 'VIC',
    NSW = 'NSW',
    SA = 'SA',
    WA = 'WA',
    ACT = 'ACT',
    NT = 'NT',
    TAS = 'TAS',
    QLD = 'QLD'
};

enum ServiceType {
    Power = 'Power',
    Gas = 'Gas'
}

enum FuelType {
    Dual = 0,
    Electricity = 1,
    Gas =2
}

const DefaultVerificationCodeRegex = /^\d{6}$/;

const DefaultDisplayDateFormat = 'DD-MMM-YYYY';
const DefaultDisplayMonthFormat = 'MMM-YYYY';

const DefaultJsonDateFormat = 'YYYY-MM-DD';
const CalendarControlDateFormat = 'DD/MM/YYYY'

const DateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';

const HourOnlyFormat = 'h A';

const DefaultVerificationCodeLength = 6;

const AuthCookieName = 'globird-portal-user';

const ImpersonateTokenName = 'impersonateToken';

const HCaptchaTokenHeaderName = 'X-HCaptcha-PassCode';

type AccountInfo = { // non-editable fields
    accountId: number,
    accountNumber: string,
    displayName: string,
    dateOfBirth: string | Moment | null
} & AccountInfoUpdateDto; // editable fields

type AccountInfoUpdateDto = {
    postcode: string,
    stateCode: StateCode | null,
    suburb: string,
    addressLine1: string,
    addressLine2: string,
    firstName: string,
    lastName: string,
    email: string,
    title: ContactTitle | null,
    phoneNumber: string,
    deliveryType: DeliveryType
}

type AccountServiceInfo = {
    accountServiceId: number,
    siteIdentifier: string,
    siteAddress: string,
    postCode: string,
    state: string,
    allAccountServiceIds: number[],
    status: EuropaAccountServiceStatus,
    concessionAddress: string,
    serviceType: ServiceType
}

type AccountBalance = {
    accountId: number,
    balance: number,
    maxRefundableAmount: number,
}

type AccountPaymentBtnFlag = {
    accountId: number,
    paymentBtnFlag: boolean
}

type DashboardInfo = {
    accountNumber: string,
    accountName: string,
    currentBalance: number,
    lastestCorrespondence: Correspondence | null,
    lastestInvoice: Invoice | null
}

type SignupInfoDto = {
    accountServiceId: number,
    serviceTypeId: ServiceType,
    transferType: TransferType | null,
    signupStatus: SignupStatus
}

enum SignupStatus {
    ApplicationPaused = 'ApplicationPaused',
    SignupCompleted = 'SignupCompleted',
    TransferOrMoveInInitiated = 'TransferOrMoveInInitiated',
    TransferOrMoveInCompleted = 'TransferOrMoveInCompleted'
}

enum TransferType {
    Transfer = 'TRANSFER',
    MoveIn = 'MOVEIN'
}

type Correspondence = {
    id: number | null,
    title: string,
    createdDate: Date | null
};

type ContactDto = {
    contactId: number | null,
    title: string,
    email: string,
    phoneNumber: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string | Moment | null,
    deliveryType?: DeliveryType | null
}

type SecondaryContactDto = ContactDto & {
    postcode: string,
    stateCode: StateCode | null,
    suburb: string,
    addressLine1: string,
    addressLine2: string
}

type CorrespondenceDetail = {
    id: number | null,
    contentBody: string,
    documentList: Document[] | null
};

type Document = {
    documentId: number,
    name: string,
    isReversedInvocie: boolean
}

type Payment = {
    paymentAmount: number,
    paymentType: string,
    paymentStatus: string,
    paymentReceivedDate: Date,
    paymentReceiptId: string
};

enum MeterType {
    Unknown = 'Unknown',
    PowerInterval = 'PowerInterval',
    PowerBasic = 'PowerBasic',
    GasBasic = 'GasBasic',
    GasInterval = 'GasInterval', // as 2019, is not supported yet
    GasHotWater = 'GasHotWater' // exists for NSW & SA only
}

enum DeliveryType {
    Email = "Email",
    PostalMail = "PostalMail"
}

type MeterDto = {
    meterSerial: string,
    status: string | null,
    meterType: MeterType,
    registers: RegisterDto[] | null
}

type AccountMeterDto = MeterDto & {
    accountServiceIds: number[],
    nmi: string
}

type RegisterDto = {
    registerId: string | null,
    suffix: string | null,
    status: string | null,
    registerType: MeterType
}

type BasicMeterRead = {
    readDate: Date | null,
    quality: string | null,
    meterIndex: number,
    identifier: string,
    serial: string,
    registerId: string | null
}

type IntervalMeterRead = {
    readDate: Date | null,
    quality: string | null,
    usage: number
}

type Invoice = {
    invoiceNumber: number | null,
    issuedDate: Date | null,
    dueDate: Date | null,
    amount: number,
    discountedAmount: number,
    documentId: number | null
}

type CallAssistanceInfo = {
    requestContent: string | null,
    phoneNumber: string,
    scheduleCallbackTime: Date
}

type EmailAssistanceInfo = {
    accountId: number,
    requestContent: string
}

type AccountServiceStatusDateDic = Record<number, AccountServiceStatusDateDto>;
type AccountServiceHasPendingReadDic = Record<number, boolean>;

class ServiceResponse<T> {
    success: boolean = false;
    message: string = '';
    data: T | null = null;

    constructor(
        success?: boolean,
        message?: string,
        data?: T | null) {

        success !== undefined && (this.success = success);
        message && (this.message = message);
        data !== undefined && data !== null && (this.data = data);
    }
};

class PageResponse<T> {
    totalCount: number = 0;
    data: T[] = [];
}

class Meter {
    meterNumber: string;
    status: string;
    constructor(meterNumber: string, status: string) {
        this.meterNumber = meterNumber;
        this.status = status;
    }
}

class MeterReadMeter {
    meterReadType: string;
    serialNumber: string;
    suffix: string;
    serialStatus: string;
    suffixStatus: string;
    constructor(
        meterReadType: string,
        serialNumber: string,
        suffix: string,
        serialStatus: string,
        suffixStatus: string) {
        this.meterReadType = meterReadType;
        this.serialNumber = serialNumber;
        this.suffix = suffix;
        this.serialStatus = serialStatus;
        this.suffixStatus = suffixStatus;
    }
    get meterId() {
        return this.meterReadType + this.serialNumber;
    }
}

type TimePeriod = {
    startTime: string,
    endTime: string
}

class MeterReadRequest {
    identifier: string;
    serialNumber: string;
    fromDate: string | null;
    toDate: string | null;
    isSmart: boolean;
    isAcrossAccount: boolean;

    constructor(
        identifier: string,
        serialNumber: string,
        fromDate: string | null = null,
        toDate: string | null = null,
        isSmart: boolean,
        isAcrossAccount: boolean) {

        this.identifier = identifier;
        this.serialNumber = serialNumber;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.isSmart = isSmart;
        this.isAcrossAccount = isAcrossAccount;
    }
}

type MeterReadBasicModel = {
    readIndex: number;
    chargeType: string;
    suffix: string;
    meterStatus: string;
    readDate: Date;
}

type MeterReadSmartModel = {
    usage: number;
    usageArray: number[];
    chargeType: string;
    suffix: string;
    meterStatus: string;
    readDate: Date;
    timePeriods: Array<TimePeriod>;
}

class BaseSite {
    accountServiceId: number;
    nmi: string;
    siteAddressString: string;
    stateCode: string;
    closedDate: Date | null;
    pendingNoSO: boolean;
    constructor(accountServiceId: number, nmi: string, siteAddressString: string, stateCode: string, pendingNoSO: boolean, closedDate: Date | null = null) {
        this.accountServiceId = accountServiceId;
        this.nmi = nmi;
        this.siteAddressString = siteAddressString;
        this.stateCode = stateCode;
        this.closedDate = closedDate;
        this.pendingNoSO = pendingNoSO;
    }
}

class SiteMeter extends BaseSite {
    serviceType: string;
    meters: Array<Meter>;
    constructor(accountServiceId: number, nmi: string, siteAddressString: string, stateCode: string, pendingNoSO: boolean, serviceType: string, meters: Array<Meter>) {
        super(accountServiceId, nmi, siteAddressString, stateCode, pendingNoSO);
        this.serviceType = serviceType;
        this.meters = meters;
    }
}

interface RootState {
    user: {
        data: User
    }
}

type CreditCardDirectDebit = {
    CardNumber: string,
    CardHolderName: string,
    CardExp: Moment
}

type BankAccountDirectDebit = {
    AccountNumber: string,
    AccountHolderName: string,
    AccountBsb: string
}

type DirectDebitInfo = {
    paymentMethodTypeId: string,
    creditCardNumberLastDigits: string | null,
    bankAccountLastDigits: string | null,
    bsbLastDigits: string | null
}

enum EuropaDirectDebitType {
    Bank = 'Bank',
    Card = 'Card',
    Cheque = 'Cheque'
}

enum ContactType {
    Email = 1,
    Call = 2
}

enum DirectPaymentMethod {
    CreditCard = 'CreditCard',
    // Paypal = 2
}

enum VerificationCodePurpose {
    LinkAccount = 'LinkAccount',
    ChangeEuropaAccountContact = 'ChangeEuropaAccountContact',
    ChangeLogin = 'ChangeLogin',
    VerifyEmailAddress = 'VerifyEmailAddress'
}

enum EuropaAccountServiceStatus {
    PendingSwitch = 'PendingSwitch',
    PendingMoveIn = 'PendingMoveIn',
    TransferRequested = 'TransferRequested',
    TransferRejected = 'TransferRejected',
    TransferObjected = 'TransferObjected',
    HoldDontSwitchYet = 'HoldDontSwitchYet',
    Switched = 'Switched',
    Closed = 'Closed',
    PendingSwitchOut = 'PendingSwitchOut',
    PendingSwitchResults = 'PendingSwitchResults',
    InvalidNmi = 'InvalidNmi',
    TransferPending = 'TransferPending',
    AddressMismatchError = 'AddressMismatchError',
    NmiDiscoveryRequested = 'NmiDiscoveryRequested',
    ErrorInNmiDiscoveryRequested = 'ErrorInNmiDiscoveryRequested',
    StandingDataRequested = 'StandingDataRequested',
    ErrorInStandingDataRequested = 'ErrorInStandingDataRequested',
    ErrorInactiveSite = 'ErrorInactiveSite',
    ErrorNoDailyPricePlanCanBeAssigned = 'ErrorNoDailyPricePlanCanBeAssigned',
    TransferCancelled = 'TransferCancelled',
    HoldPendingMoveIn = 'HoldPendingMoveIn',
    HoldGasSwitch = 'HoldGasSwitch',
    AccountConsolidation = 'AccountConsolidation',
    AccountClosedToWorkAroundSystem = 'AccountClosedToWorkAroundSystem',
    AccountRequiresAttention = 'AccountRequiresAttention',
    SwitchOutOutstandingDebt = 'SwitchOutOutstandingDebt',
    CRFromOtherParticipantCancelled = 'CRFromOtherParticipantCancelled',
    NoMeterDataInNMIResponse = 'NoMeterDataInNMIResponse',
    AccountDisconnectedNonPayment = 'AccountDisconnectedNonPayment',
    PendingSwitchOutRetro = 'PendingSwitchOutRetro',
    NewConnectionPending = 'NewConnectionPending'
}

enum AccountServiceStatusForMoveOut {
    Active = 'Active',
    PendingMoveOut = 'Pending Move Out'
}

const AccountServiceStatusForDisplay: Partial<Record<EuropaAccountServiceStatus, string>> = {
    // sorted object, so that the service with the most common status is at the top when selecting from a dropdown
    [EuropaAccountServiceStatus.Switched]: 'Active',
    [EuropaAccountServiceStatus.PendingMoveIn]: 'Pending move in',
    [EuropaAccountServiceStatus.PendingSwitch]: 'Pending switch',
    [EuropaAccountServiceStatus.TransferPending]: 'Transfer pending',
    [EuropaAccountServiceStatus.PendingSwitchOut]: 'Pending switch out',
    [EuropaAccountServiceStatus.Closed]: 'Closed'
}

enum ActiveMeterStatus {
    Turnedon = 'Turnedon',
    Energized = 'Energized'
}

interface AccountServiceStatus {
    [x: string]: string
}

enum VerificationCodeType {
    Email = 'Email',
    Sms = 'Sms'
}

type SendCodeRequest = {
    Purpose: VerificationCodePurpose,
    RecipientIdentifier: string,
    CodeType: VerificationCodeType
}

type VerifyCodeRequest = {
    Purpose: VerificationCodePurpose,
    RecipientIdentifier: string,
    Code: string,
    CorrelationId: string,
    CodeType: VerificationCodeType
}

type VerifyCodeResponse = {
    isSuccessful: boolean,
    verificationTicketId: string | null
}

type SendEmailVerificationCodeRequest = {
    emailAddress: string
}

type DirectPayment = {
    PaymentMethod: DirectPaymentMethod,
    Amount: string,
    CardNumber: string,
    CardHolderName: string,
    CardCvn: string | null,
    CardExp: Moment
}

type ActivateAccount = {
    EmailAddress: string | null,
    Token: string,
    DateOfBirth: string | null | Moment,
    // FirstName: string | null,
    // LastName: string | null,
    PhoneId: string | null,
    // EmailId: string | null,
    Password: string,
    ConfirmPassword: string,
    EmailVerificationCode: string | null,
    EmailVerificationCorrelationId: string | null,
    sevToken: string | null
}

type RegisterAccountDto = {
    EmailAddress: string,
    EuropaAccountNumber: string
}

type LinkAccount = {
    AccountNumber: string,
    DateOfBirth: string | Moment | null,
    FirstName: string,
    LastName: string
}

type ChangeLoginDto = {
    newEmailAddress: string,
    password: string,
    isFromUpdateAccountSummary: boolean,
    verifyEmailTicketId: string
}

enum RegistrationOutcome {
    ActivationEmailSent = 'ActivationEmailSent',
    AlreadyRegistered = 'AlreadyRegistered',
    InformationMismatch = 'InformationMismatch',
    UserUnavailable = 'UserUnavailable'
};

enum RequestResetPasswordOutcome {
    Succeeded = "Succeeded",
    Failed = "Failed",
    RequireMoreInfo = "RequireMoreInfo",
    InvalidInfo = "InvalidInfo",
    AlreadyLoggedIn = "AlreadyLoggedIn",
    UserUnavailable = "UserUnavailable"
}

enum ChangeLoginOutcome {
    InvalidPassword = 'InvalidPassword',
    NewEmailOccupied = 'NewEmailOccupied',
    ChangeEmailSucceeded = 'ChangeEmailSucceeded',
    ChangeEmailAlreadySucceeded = 'ChangeEmailAlreadySucceeded'
}

enum ActivationMetaOutcome {
    RequireMoreInfo = 'RequireMoreInfo',
    NoNeedMoreInfo = 'NoNeedMoreInfo',
    Activated = 'Activated',
    InvalidParams = 'InvalidParams',
    LinkExpired = 'LinkExpired',
    MaxAttemptReached = 'MaxAttemptReached',
    UserUnavailable = 'UserUnavailable'
};

enum ActivationOutcome {
    Succeeded = 'Succeeded',
    InvalidInfo = 'InvalidInfo',
    InvalidPassword = 'InvalidPassword',
    Errored = 'Errored',
    MaxAttemptReached = 'MaxAttemptReached',
    InvalidVerificationCode = 'InvalidVerificationCode'
};

enum ContactTitle {
    Doctor = 'Doctor',
    Father = 'Father',
    Miss = 'Miss',
    Mr = 'Mr',
    Mrs = 'Mrs',
    Mx = 'Mx',
    Ms = 'Ms',
    Prof = 'Prof'
}

type Site = {
    nmi: string,
    address: string,
    accountServiceInfo: AccountServiceInfo
}

enum MeterReadType {
    GasBasic = 'GasBasic',
    PowerBasic = 'PowerBasic'
}

enum MeterReadQuality {
    Actual = 'A',
    Customer = 'C'
}

enum SelfReadReason {
    ImproveNextEstimation = 'ImproveNextEstimation',
    ReissueLastBill = 'ReissueLastBill',
    // IssueNextBill = 'IssueNextBill',
    Others = 'Others' //Don't allow user to select this option - not removed for compatibility with historical data
}

enum SelfReadReasonDisplay {
    ReissueLastBill = 'Re-issue the last bill',
    // IssueNextBill = 'Issue the next bill',
    ImproveNextEstimation = 'Help to improve estimation',
    Others = 'Others'
}

enum SelfReadReasonDesc {
    ReissueLastBill = 'Your last bill will be reversed then re-issued to reflect the meter reads you uploaded.',
    // IssueNextBill = 'Issue the next bill with usage represented by the meter reads you uploaded',
    ImproveNextEstimation = 'Help improve our estimation accuracy. This will minimize any variance on the next catch up bill.',
    Others = 'Others.'
}

type SelfReadUpload = {
    readDate: Moment | string | null,
    reads: SelfMeterRead[] | null,
    readReason: SelfReadReason | null,
    // readNote: string | null
}

//#region SelfReadDtos
type NewSelfReadUpload = {
    readDate: Moment | string | null,
    reissueInvoice: boolean,
    reads: NewSelfMeterRead[]
}

type NewSelfMeterRead = {
    meterSerial: string | null,
    readIndex: number | null | string,
    readPhotoReference: string | null,
    isMultiRegister: boolean,
    registerReads: NewRegisterRead[],
    accountServiceId: number | null,
    serviceType: ServiceType | null,
    readPhoto: File | null
}

type NewRegisterRead = {
    registerId: string | null,
    readIndex: string | null | number,
    readPhotoReference: string | null,
    readPhoto: File | null,
    allowIgnoreRegisterId: boolean
}
//#endregion

type SelfMeterRead = {
    meterSerial: string | null,
    readIndex: number | null | string,
    readPhoto64: string | null | CustomUploadFile[],
    isMultiRegister: boolean,
    registerReads: RegisterRead[] | null
}

type RegisterRead = {
    registerId: string | null,
    registerIndex: string | null | number,
    registerReadPhoto64: string | null | CustomUploadFile[],
}

interface ListSelfReadUpload {
    nmi: string,
    accountServiceId: number;
    selfReadUploadId: number;
    readDate: Moment | string;
    readReason: SelfReadReason;
    readNote: string | null;
    approvalStatus: SelfReadApprovalStatus;
    reviewReason: null | SelfReadUploadReviewReason;
    reviewNote: null | string;
    uploadDateTime: string | Moment;
    reads: ListSelfMeterRead[];
}

interface ListSelfMeterRead {
    meterSerial: string;
    accpetedMeterSerial: string | null;
    readIndex: number | null | string;
    accpetedReadIndex: number | null | string;
    readPhoto: Document | null;
    isMultiRegister: boolean;
    registerReads: ListRegisterRead[] | null;
    selfReadId: number;
    approvalStatus: SelfReadApprovalStatus | null;
    reviewReason: null | SelfReadReviewReason;
}

interface ListRegisterRead {
    registerId: string | null,
    accpetedRegisterId: string | null,
    registerIndex: string | number,
    accpetedRegisterIndex: string | number | null,
    registerReadPhoto: Document,
    approvalStatus: SelfReadApprovalStatus,
    reviewReason: null | SelfReadReviewReason,
    selfReadId: number
}

enum SelfReadUploadReviewReason {
    Approved = 'Approved', // Approved
    MeterSerialNotFound = 'MeterSerialNotFound', // Reject 
    NeedInvestigation = 'NeedInvestigation', // Approved
    MeterNumberInvisible = 'MeterNumberInvisible', // Reject
    MissingMeterReads = 'MissingMeterReads', // Reject
    Others = 'Others'
}

enum SelfReadApprovalStatus {
    PendingApproval = 'PendingApproval',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled'
}

const SelfReadUploadReviewReasonDesc: Record<SelfReadUploadReviewReason, string> = {
    Approved: '',
    MeterSerialNotFound: 'The provided meter serial number does not match with our records for your property. Please double check the meter is correct and resubmit or contact us.',
    NeedInvestigation: 'The meter serial number matches your account. However, the meter read value is lower than your last recorded reading. We will request an investigation with your local distributor and follow up with you once we receive a response.',
    MeterNumberInvisible: 'The attached image doesn\'t display a clear view of the Meter Serial number and/or Usage number. Please resubmit an image ensuring these numbers can be clearly read.',
    MissingMeterReads: 'Your account shows multiple active meters for the property, and we have not received meter images for all of these active meters. Please ensure all images are uploaded for all of your active meters.',
    Others: 'Please review the Feedback for more information.'
}

enum SelfReadReviewReason {
    Approved = 'Approved', // Approved
    MeterSerialNotFound = 'MeterSerialNotFound', // Reject 
    NeedInvestigation = 'NeedInvestigation', // Approved
    MeterNumberInvisible = 'MeterNumberInvisible' // Reject
}

const SelfReadReviewReasonDesc: Record<SelfReadReviewReason, string> = {
    Approved: 'Approved',
    MeterSerialNotFound: 'The meter serial could not be found.',
    NeedInvestigation: 'Approved after investigation',
    MeterNumberInvisible: 'Meter serial is not visible in the photo.',
    // Others: 'Please refer to the feedback.'
}

interface SelfReadUploadReview {
    approvalStatus: SelfReadApprovalStatus;
    selfReadDate: string | Moment;
    selfReadReason: SelfReadReason;
    reviewReason: null | SelfReadUploadReviewReason;
    selfReadNote: null | string;
    reviewNote: null | string;
    uploadDateTime: string | Moment;
    selfReads: SelfReadReview[];
    nmi: string,
    accountServiceId: number;
    selfReadUploadId: number;
}

interface SelfReadReview {
    meterSerial: string;
    actualMeterSerial: null | string;
    registerId: null | string;
    actualRegisterId: null | string;
    registerSuffix: null | string;
    readIndex: number;
    actualReadIndex: null | number;
    approvalStatus: SelfReadApprovalStatus;
    reviewReason: null | SelfReadReviewReason;
    photoDocuments: Document[];
    selfReadId: number;
}

interface CaptchaResultDto {
    requireHCaptcha: boolean,
    requireRetryCaptCha: boolean
}

interface LookupReferralLinkDto {
    link: string
    expiryDate: string
}

interface LoginResultDto extends CaptchaResultDto {
    isLoginSucceeded: boolean,
    alreadyLoggedIn: boolean
}

interface SendOtpResult extends CaptchaResultDto {
    alreadyLoggedIn: boolean,
    additionalVerificationRequired: boolean
}

interface OtpLoginRequest {
    emailAddress: string,
    code: string,
    hCaptchaToken: string | null,
    dateOfBirth: string | null
}

interface ChangeLoginResultDto extends CaptchaResultDto {
    changeLoginOutcome: ChangeLoginOutcome | null
}

interface ChangePasswordResultDto extends CaptchaResultDto {
    isSucceeded: boolean
}

interface RegisterResultDto extends CaptchaResultDto {
    registrationOutcome: RegistrationOutcome | null
}

interface AccountServiceStatusDateDto {
    accountServiceId: number;
    nmi: string;
    status: EuropaAccountServiceStatus;
    moveInDate: string | null | Moment;
    moveOutDate: string | null | Moment;
    frmpDate: string | null | Moment;
    accountClosedDate: string | null | Moment;
}

interface CalendarControlDto {
    minDate?: Moment | string;
    maxDate?: Moment | string;
    daysOfWeekDisabled: number[];
    disabledDates: string[];
    sameDayReEnCutOffTime: Moment;
}

interface AccountServiceMoveOutDetailDto {
    accountServiceId: number;
    nmi: string;
    siteAddress: string;
    status: EuropaAccountServiceStatus;
    state: string;
    moveOutFee: number;
    moveOutDate: null | Moment;
    statusForDisplay: AccountServiceStatusForMoveOut;
    calendarControlDto: CalendarControlDto;
    hasLifeSupport: boolean;
    isBasicMeter: boolean;
}

interface AccountBalanceDto {   
    balance: number;
    maxRefundableAmount: number;
}

interface AccountServiceMoveOutDto {
    accountServiceId: number;
    moveOutDate: Moment;
}

interface AccountOwnerInfoDto {
    accountId: number;
    contactId: number;
    contactTypeId: number;
    firstName: string;
    middleName: string;
    lastName: string;
}

interface ConcessionCardTypeDto {
    code: string;
    description: string;
}

interface AddConcessionCardResultDto {
    addConcessionCardResult: AddConcessionCardResult; 
}

interface ConcessionFormInputDto {
    contactId: number,
    accountServiceId: number,
    cardType: string,
    cardNumber: string,
    startDate: Moment,
    expiryDate?: Moment
}

enum ConcessionCardStatus {
    PendingValidation = 'PendingValidation',
    ValidationRequested = 'ValidationRequested',
    UnderInvestigation = 'UnderInvestigation',
    Validated = 'Validated',
    Rejected = 'Rejected'
}

enum AddConcessionCardResult {
    Success = 'Success',
    DuplicatePending = 'DuplicatePending',
    Failed = 'Failed'
}

interface ConcessionCardInfoHistoryLiteDto {
    concessionCardInfoHistoryId: number,
    concessionCardNumber: string,
    concessionCardType: string,
    cardHolderName: string,
    concessionCardStatus: string,
    startDate: Moment,
    endDate?: Moment
}

type UnavailableState = {
    unavailableFlag: boolean;
}

const ProdHostNames = [
    'myaccount.globirdenergy.com.au',
    'globirdcustomerportalprod-stage.azurewebsites.net',
    'globirdcustomerportalprod.azurewebsites.net'
];

const UnavailableSessionKey = 'UnavailableState';

interface GetAccessTokenResultDto {
    accessToken: string | null;
}

const SelfReadPhotoFileExtensions = ".png,.jpg,.jpeg,.heic,.heif";

interface UploadParseMeterPhotoResultDto {
    meter: AccountMeterDto | null,
    uploadReference: string
}

type AccountServiceReenDetailDto = {
    accountId: number;
    accountServiceId: number;
    serviceOrderId: number;
    nmi: string;
    siteAddress: string;
    stateCode: string;
    status: string;
    reenDate: Date | string;
    requestOn: Date | string;
    requestBy: string;
    calendarControlDto: CalendarControlDto;
    reconnectionFee: string;
}

type ReconnectionInfo = {
    accountServiceReenDetailDtos : Array<AccountServiceReenDetailDto>;
    displayReconnectionPage: boolean;
}

type ReconnectionRequestDto = {
    accountId: number;
    accountServiceId: number;
    serviceOrderId: number;
    reenDate: Moment | Date;
}

enum ReconnectionStatus {
    Pending = 'Pending',
    Complete = 'Complete',
    Failed = 'Failed',
}

type CallbackInfo = {
    callerPhoneNumber: string;
    scheduleUtcDateTime: Moment | Date | null;
    callbackType: string;
}

type CallbackCategory = {
    callbackCategoryId: number;
    categoryName: string;
}

type LodgeCallbackRequest = {
    phoneNumber: string;
    scheduledDateTime?: string;
    categoryId: number;
}

type CallbackContactDto = {
    callbackContactId: number;
    phoneNumber: string;
    contactName: string;
}

enum PreferredCallbackTime {
    Anytime = 'anytime',
    Scheduled = 'scheduled'
}

export type {
    LinkAccount,
    User,
    RootState,
    Account,
    AccountServiceInfo,
    ContactDto,
    Invoice,
    Payment,
    AccountInfo,
    DashboardInfo,
    MeterDto,
    BasicMeterRead,
    IntervalMeterRead,
    Correspondence,
    CorrespondenceDetail,
    Document,
    CallAssistanceInfo,
    EmailAssistanceInfo,
    CreditCardDirectDebit,
    BankAccountDirectDebit,
    AccountBalance,
    AccountPaymentBtnFlag,
    DirectPayment,
    SendCodeRequest,
    VerifyCodeRequest,
    VerifyCodeResponse,
    ActivateAccount,
    MeterReadBasicModel,
    MeterReadSmartModel,
    TimePeriod,
    RegisterAccountDto,
    DirectDebitInfo,
    AccountInfoUpdateDto,
    ChangeLoginDto,
    Site,
    RegisterRead,
    SelfMeterRead,
    SelfReadUpload,
    SelfReadReview,
    SelfReadUploadReview,
    ListRegisterRead,
    ListSelfMeterRead,
    ListSelfReadUpload,
    LoginResultDto,
    SendOtpResult,
    ChangeLoginResultDto,
    ChangePasswordResultDto,
    RegisterResultDto,
    AccountServiceStatusDateDto,
    AccountServiceMoveOutDetailDto,
    AccountBalanceDto,
    AccountServiceMoveOutDto,
    AccountOwnerInfoDto,
    ConcessionCardTypeDto,
    ConcessionFormInputDto,
    ConcessionCardInfoHistoryLiteDto,
    UnavailableState,
    GetAccessTokenResultDto,
    NewSelfReadUpload,
    NewSelfMeterRead,
    NewRegisterRead,
    AccountMeterDto,
    UploadParseMeterPhotoResultDto,
    AccountServiceStatusDateDic,
    AccountServiceHasPendingReadDic,
    AccountServiceReenDetailDto,
    ReconnectionInfo,
    ReconnectionRequestDto,
    CalendarControlDto,
    LookupReferralLinkDto,
    AddConcessionCardResultDto,
    AccountServiceStatus,
    SecondaryContactDto,
    SignupInfoDto,
    OtpLoginRequest,
    SendEmailVerificationCodeRequest,
    CallbackInfo,
    CallbackCategory,
    LodgeCallbackRequest,
    CallbackContactDto
};

export {
    ServiceResponse,
    PageResponse,
    Meter,
    SiteMeter,
    BaseSite,
    MeterReadMeter,
    MeterReadRequest,
    DirectPaymentMethod,
    VerificationCodePurpose,
    VerificationCodeType,
    ContactType,
    RegistrationOutcome,
    ActivationMetaOutcome,
    EuropaDirectDebitType,
    StateCode,
    DefaultDisplayDateFormat,
    DefaultVerificationCodeLength,
    DefaultVerificationCodeRegex,
    ContactTitle,
    DefaultDisplayMonthFormat,
    AuthCookieName,
    DefaultJsonDateFormat,
    CalendarControlDateFormat,
    ChangeLoginOutcome,
    RequestResetPasswordOutcome,
    MeterReadType,
    SelfReadReason,
    SelfReadReasonDesc,
    MeterType,
    SelfReadReasonDisplay,
    SelfReadReviewReason,
    SelfReadUploadReviewReason,
    SelfReadUploadReviewReasonDesc,
    SelfReadReviewReasonDesc,
    ActivationOutcome,
    ImpersonateTokenName,
    HCaptchaTokenHeaderName,
    ProdHostNames,
    EuropaAccountServiceStatus,
    AccountServiceStatusForMoveOut,
    DeliveryType,
    ConcessionCardStatus,
    SelfReadApprovalStatus,
    UnavailableSessionKey,
    ServiceType,
    FuelType,
    SelfReadPhotoFileExtensions,
    ReconnectionStatus,
    ActiveMeterStatus,
    AccountServiceStatusForDisplay,
    MeterReadQuality,
    AddConcessionCardResult,
    SignupStatus,
    TransferType,
    DateTimeFormat,
    PreferredCallbackTime,
    HourOnlyFormat
};