import {
    AccountInfo,
    Correspondence,
    CorrespondenceDetail,
    ServiceResponse,
    PageResponse,
    Invoice,
    Payment,
    MeterDto,
    BasicMeterRead,
    IntervalMeterRead,
    SiteMeter,
    DashboardInfo,
    BaseSite,
    CreditCardDirectDebit,
    BankAccountDirectDebit,
    MeterReadMeter,
    MeterReadRequest,
    MeterReadBasicModel,
    MeterReadSmartModel,
    ContactDto,
    DirectPayment,
    VerifyCodeRequest,
    SendCodeRequest,
    VerifyCodeResponse,
    EmailAssistanceInfo,
    LinkAccount,
    ActivationMetaOutcome,
    DirectDebitInfo,
    AccountInfoUpdateDto,
    SelfReadUpload,
    SelfReadUploadReview,
    AccountServiceStatusDateDto,
    AccountServiceMoveOutDetailDto,
    AccountServiceMoveOutDto,
    AccountOwnerInfoDto,
    ConcessionCardTypeDto,
    ConcessionFormInputDto,
    ConcessionCardInfoHistoryLiteDto,
    FuelType,
    AccountMeterDto,
    UploadParseMeterPhotoResultDto,
    NewSelfReadUpload,
    AccountServiceReenDetailDto,
    ReconnectionInfo,
    ReconnectionRequestDto,
    LookupReferralLinkDto,
    AddConcessionCardResultDto,
    AddConcessionCardResult,
    SecondaryContactDto,
    SignupInfoDto,
    SendEmailVerificationCodeRequest,
    CallbackInfo,
    CallbackCategory,
    LodgeCallbackRequest,
    CallbackContactDto,
    DateTimeFormat,
    AccountBalanceDto
} from 'data/CustomerPortalTypes';
import { FormFile, HttpMethod, JsonHeader } from 'utilities/HttpHelper';
import { delay, handleResponse, RequestSameOrigin, StaticDataCache } from 'utilities/Utility';
import { expDateFormat } from 'components/Shared/CreditCardFields';
import * as queryString from 'query-string';
import moment, { Moment } from 'moment';

const accountServiceStatusDateCache: Record<number, StaticDataCache<AccountServiceStatusDateDto>> = {};
const moveOutDetailsCache: Record<number, StaticDataCache<AccountServiceMoveOutDetailDto>> = {};
const accountOwnersCache: Record<number, StaticDataCache<AccountOwnerInfoDto[]>> = {};
const accountConcessionCardsCache: Record<number, StaticDataCache<ConcessionCardInfoHistoryLiteDto[]>> = {};
const accountMeterCache: Record<string, StaticDataCache<AccountMeterDto[]>> = {};
const concessionCardTypeCache: Record<string, StaticDataCache<ConcessionCardTypeDto[]>> = {};
const callbackContactsCache: Record<number, StaticDataCache<CallbackContactDto[]>> = {};

const cacheTimeOut: number = 300000; // 5 minutes

const contentDispositionRegex = /filename=(?<fileName>[^;]*)(;|$)/;
const requestDataService = {
    async getAccountInfo(accountId: number): Promise<ServiceResponse<AccountInfo> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/accountinfo?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return handleResponse(responseData);
    },

    async updateAccountInfo(
        accountId: number,
        updateAccountInfoDto: AccountInfoUpdateDto,
        verifyPhoneNumberTicketId: string | null,
        verifyEmailTicketId: string | null): Promise<ServiceResponse<boolean> | null> {

        let responseData = fetch(new RequestSameOrigin(
            `/api/account/accountinfo?accountId=${accountId}`,
            {
                method: HttpMethod.PUT,
                headers: JsonHeader,
                body: JSON.stringify({
                    updateBillingContactDto: updateAccountInfoDto,
                    verifyPhoneNumberTicketId: verifyPhoneNumberTicketId,
                    verifyEmailTicketId: verifyEmailTicketId
                })
            }
        ));

        return handleResponse(responseData);
    },

    async getAuthorizedContacts(accountId: number): Promise<ServiceResponse<ContactDto[]> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/authorizedcontact?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return handleResponse(responseData);
    },

    async upsertAuthorizedContact(accountId: number, newContact: ContactDto): Promise<ServiceResponse<boolean> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/authorizedcontact?accountId=${accountId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(newContact)
            }
        ));

        return handleResponse(responseData);
    },

    async deleteAuthorizedContact(accountId: number, contactId: number): Promise<ServiceResponse<boolean> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/authorizedcontact?accountId=${accountId}&contactId=${contactId}`,
            {
                method: HttpMethod.DELETE,
                headers: JsonHeader
            }
        ));

        return handleResponse(responseData);
    },

    async getJointOwners(accountId: number): Promise<ServiceResponse<SecondaryContactDto[]> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/getjointowners?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return handleResponse(responseData);
    },

    async updateJointOwner(accountId: number, updatedContact: SecondaryContactDto): Promise<ServiceResponse<boolean> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/updatejointowner?accountId=${accountId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(updatedContact)
            }
        ));

        return handleResponse(responseData);
    },

    async submitAssistance(contactType: string, accountId: number, assistance: EmailAssistanceInfo): Promise<ServiceResponse<string> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/assistance/submitassistance?accountId=${accountId}&contactType=${contactType}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(assistance)
            }
        ));

        return handleResponse(responseData);
    },

    async getDashboardData(accountId: number): Promise<ServiceResponse<DashboardInfo> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/dashboard?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return handleResponse(responseData);
    },

    async getAccountCorrespondenceList(accountId: number | undefined, offset: number, limit: number): Promise<ServiceResponse<PageResponse<Correspondence>> | null> {
        if (!accountId) {
            return null;
        }
        let responseData = fetch(new RequestSameOrigin(
            `/api/correspondence/getcorrespondencelist?accountId=${accountId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify({
                    offset: offset,
                    limit: limit
                })
            }
        ));

        return await handleResponse(responseData);
    },

    async getCorrespondenceDetailAsync(accountId: number, emailId: number): Promise<ServiceResponse<CorrespondenceDetail> | null> {
        return await handleResponse(
            fetch(new RequestSameOrigin(
                `/api/correspondence/detail?accountId=${accountId}&emailId=${emailId}`,
                {
                    method: HttpMethod.GET,
                    headers: JsonHeader
                }
            )));
    },

    async getAccountInvoicesAsync(accountId: number | undefined,
        startDate: Date | undefined | null,
        endDate: Date | undefined | null,
        offset: number,
        limit: number): Promise<ServiceResponse<PageResponse<Invoice>> | null> {

        if (!accountId) {
            return null;
        }

        return await handleResponse(
            fetch(new RequestSameOrigin(
                `/api/transaction/invoice?accountId=${accountId}`,
                {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
                    body: JSON.stringify({
                        startDate: startDate,
                        endDate: endDate,
                        offset: offset,
                        limit: limit
                    })
                }
            )));
    },

    async getAccountPaymentList(accountId: number | undefined,
        startDate: Date | undefined | null,
        endDate: Date | undefined | null,
        offset: number,
        limit: number): Promise<ServiceResponse<PageResponse<Payment>> | null> {
        if (!accountId) {
            return null;
        }
        let responseData = fetch(new RequestSameOrigin(
            `/api/transaction/paymenthistory?accountId=${accountId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify({
                    startDate: startDate,
                    endDate: endDate,
                    offset: offset,
                    limit: limit
                })
            }
        ));

        return await handleResponse(responseData);
    },

    async getSiteMetersAsync(
        accountServiceId: number,
        includeRemovedMeters: boolean = false): Promise<ServiceResponse<MeterDto[]> | null> {
        if (!accountServiceId) {
            return null;
        }
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/meter?accountServiceId=${accountServiceId}&includeRemovedMeters=${includeRemovedMeters}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }));

        return await handleResponse(
            responseData, true, false);
    },

    async getLastActualRead(accountServiceId: number | undefined,
        siteIdentifier: string | undefined,
        meterNumber: string | undefined): Promise<ServiceResponse<BasicMeterRead> | null> {

        if (!accountServiceId || !siteIdentifier || !meterNumber) {
            return null;
        }
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/lastactualread?accountServiceId=${accountServiceId}&siteIdentifier=${siteIdentifier}&meterNumber=${meterNumber}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData);
    },

    async getBasicMeterReadList(
        accountServiceId: number | undefined,
        identifier: string | undefined,
        meterNumber: string | undefined,
        offset: number,
        limit: number): Promise<ServiceResponse<PageResponse<BasicMeterRead>> | null> {
        if (!accountServiceId || !identifier || !meterNumber) {
            return null;
        }
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/basicmeterreadlist?accountServiceId=${accountServiceId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify({
                    identifier: identifier,
                    meterNumber: meterNumber,
                    offset: offset,
                    limit: limit
                })
            }
        ));

        return await handleResponse(responseData);
    },

    async getIntervalMeterReadList(
        accountServiceId: number | undefined,
        identifier: string | undefined,
        meterNumber: string | undefined,
        offset: number,
        limit: number): Promise<ServiceResponse<PageResponse<IntervalMeterRead>> | null> {
        if (!accountServiceId || !identifier || !meterNumber) {
            return null;
        }
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/smartmeterreadlist?accountServiceId=${accountServiceId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify({
                    identifier: identifier,
                    meterNumber: meterNumber,
                    offset: offset,
                    limit: limit
                })
            }
        ));
        return await handleResponse(responseData);
    },

    async getAccountActivateMeta(
        emailAddress: string,
        emailId: string,
        token: string,
        sevToken: string): Promise<ServiceResponse<ActivationMetaOutcome> | null> {

        if ((!emailAddress && !emailId)
            || (emailAddress && emailId)
            || !token) {

            return new ServiceResponse<ActivationMetaOutcome>(
                true,
                '',
                ActivationMetaOutcome.InvalidParams);
        }

        let requestUrl = `/api/account/activatemeta?token=${encodeURIComponent(token)}`;
        requestUrl += emailAddress ? `&emailAddress=${encodeURIComponent(emailAddress)}` : `&emailId=${encodeURIComponent(emailId)}`;
        requestUrl += sevToken ? `&sevToken=${encodeURIComponent(sevToken)}` : '';

        const responseData = fetch(new RequestSameOrigin(
            requestUrl,
            {
                method: HttpMethod.GET,
            }
        ));

        return await handleResponse(responseData, true, true);
    },

    async submitMeterRead(accountId: number, accountServiceId: number, customerMeterRead: FormData): Promise<ServiceResponse<boolean> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/meterread?accountServiceId=${accountServiceId}`,
            {
                method: HttpMethod.POST,
                // headers: [['Content-Type', 'multipart/form-data']],
                body: customerMeterRead
            }
        ));

        return handleResponse(responseData);
    },

    async getSiteMeter(accountId: number): Promise<ServiceResponse<Array<SiteMeter>> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/site?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return handleResponse(responseData);
    },

    async getBaseSites(accountId: number): Promise<ServiceResponse<Array<BaseSite>> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/basesite?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return handleResponse(responseData);
    },

    async getPendingSites(accountId: number): Promise<ServiceResponse<Array<BaseSite>> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/pendingsite?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return handleResponse(responseData);
    },

    async getMeterListFromRead(accountServiceId: number): Promise<ServiceResponse<Array<MeterReadMeter>> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/readmeters?accountServiceId=${accountServiceId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }));

        const data = await handleResponse<Array<MeterReadMeter>>(responseData);

        if (data?.data) {
            const list = new Array<MeterReadMeter>();
            data.data.map((v: MeterReadMeter) => list.push(
                new MeterReadMeter(
                    v.meterReadType,
                    v.serialNumber,
                    v.suffix,
                    v.serialStatus,
                    v.suffixStatus
                )
            ));
            data.data = list;
        }

        return data;
    },

    async getPowerMeterTypes(nmi: string): Promise<ServiceResponse<Record<string, string>> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `api/site/GetPowerMeterTypes?nmi=${nmi}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }));
        return await handleResponse(responseData);
    },

    async getBasicMeterRead(accountServiceId: number, meterReadRequest: MeterReadRequest): Promise<ServiceResponse<Record<string, Array<MeterReadBasicModel>>> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/basicmeterread?accountServiceId=${accountServiceId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(meterReadRequest)
            }
        ));

        return handleResponse(responseData);
    },

    async getSmartMeterRead(accountServiceId: number, meterReadRequest: MeterReadRequest): Promise<ServiceResponse<Array<MeterReadSmartModel>> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/smartmeterread?accountServiceId=${accountServiceId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(meterReadRequest)
            }
        ));

        return handleResponse(responseData);
    },

    async downLoadCsvFile(accountServiceId: number, meterReadRequest: MeterReadRequest) {
        try {
            const res = await fetch(new RequestSameOrigin(
                `/api/site/generatecsvfile?accountServiceId=${accountServiceId}`,
                {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
                    body: JSON.stringify(meterReadRequest)
                }));

            const blob = await res.blob();

            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            const contentDisposition = res.headers.get('Content-Disposition');

            let fileName = "meter data.csv";

            if (contentDisposition) {
                const matched = contentDispositionRegex.exec(contentDisposition);

                if (matched?.groups?.['fileName']) {
                    fileName = matched?.groups?.['fileName'];
                }
            }

            a.href = url;
            a.download = fileName;
            a.click();

            window.URL.revokeObjectURL(url);
        }
        catch { }
    },

    async getFrmpDate(accountId: number, accountServiceIds: Array<number>): Promise<ServiceResponse<Record<number, Record<string, string>>> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/site/getfrmpdate?accountId=${accountId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(accountServiceIds)
            }
        ));

        return handleResponse(responseData);
    },

    async submitMoveOutAsync(moveOutSubmissions: Map<number, AccountServiceMoveOutDto>, accountId: number): Promise<ServiceResponse<AccountServiceMoveOutDto> | null> {
        if (moveOutSubmissions.size < 1) {
            return null;
        }

        const res: ServiceResponse<AccountServiceMoveOutDto> | null = await handleResponse(
            fetch(new RequestSameOrigin(
                `/api/site/moveout?accountId=${accountId}`,
                {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
                    body: JSON.stringify([...moveOutSubmissions.values()])
                })));

        if (res?.success) {
            moveOutSubmissions.forEach((_, accountServiceId) => {
                moveOutDetailsCache[accountServiceId] = StaticDataCache.Create<AccountServiceMoveOutDetailDto>(cacheTimeOut);
            });
        }

        return res;
    },

    async sendCode(request: SendCodeRequest, accountId?: number): Promise<ServiceResponse<string> | null> {
        const sendCodeUrl = accountId
            ? `/api/verification/sendcode?accountId=${accountId}`
            : `/api/verification/sendcode`;

        const responseData = fetch(new RequestSameOrigin(
            sendCodeUrl,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(request)
            }
        ));

        return await handleResponse(responseData);
    },

    async sendEmailVerificationCode(request: SendEmailVerificationCodeRequest): Promise<ServiceResponse<string> | null> {
        const responseData = fetch(new RequestSameOrigin(
            '/api/verification/sendemailverificationcode',
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(request)
            }
        ));

        return await handleResponse(responseData, true, true, undefined, true);
    },

    async verifyCode(
        request: VerifyCodeRequest,
        accountId?: number): Promise<ServiceResponse<VerifyCodeResponse> | null> {

        const verifyCodeUrl = accountId
            ? `/api/verification/verifycode?accountId=${accountId}`
            : `/api/verification/verifycode`;

        const responseData = fetch(new RequestSameOrigin(
            verifyCodeUrl,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(request)
            }));

        return await handleResponse(responseData);
    },

    async getAccountBalance(accountId: number): Promise<ServiceResponse<AccountBalanceDto> | null> {
        if (!accountId) {
            return null;
        }

        let responseData = fetch(new RequestSameOrigin(
            `/api/transaction/balance?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData);
    },

    async getAccountPaymentBtnFlag(accountId: number): Promise<ServiceResponse<boolean> | null> {
        if (!accountId) {
            return null;
        }

        let responseData = fetch(new RequestSameOrigin(
            `/api/transaction/paymentBtnDisableFlag?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData);
    },

    async getPublicHolidays(): Promise<ServiceResponse<Map<string, string[]>> | null> {
        const responseData = fetch(new RequestSameOrigin(
            `/api/publicresource/publicholidays`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        const data = await handleResponse<Record<string, string[]>>(responseData);

        const result = new ServiceResponse<Map<string, string[]>>();

        if (data?.success && data?.data) {
            const map = new Map<string, string[]>();

            for (var k in data.data) {
                map.set(k, data.data[k]);
            }

            result.success = true;

            result.data = map;
        }

        return result;
    },

    async setupDirectDebit(
        accountId: number,
        cc?: CreditCardDirectDebit,
        ba?: BankAccountDirectDebit): Promise<ServiceResponse<boolean> | null> {

        if (!accountId
            || (!cc && !ba) // either cc or ba need to be passed in
            || (!!cc && !!ba)) {

            return null;
        }

        let responseData: Promise<Response>;

        if (!!cc) {
            responseData = fetch(new RequestSameOrigin(
                `/api/directdebit/creditcard?accountId=${accountId}`,
                {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
                    body: JSON.stringify({
                        ...cc,
                        CardExp: cc.CardExp.format(expDateFormat)
                    })
                }
            ));
        }
        else {
            responseData = fetch(new RequestSameOrigin(
                `/api/directdebit/bankaccount?accountId=${accountId}`,
                {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
                    body: JSON.stringify(ba)
                }
            ));
        }

        return await handleResponse(responseData);
    },

    async deleteDirectDebit(accountId: number): Promise<ServiceResponse<boolean> | null> {
        if (!accountId) {
            return null
        }
        let responseData = fetch(new RequestSameOrigin(
            `/api/directdebit/RemoveDirectDebit?accountId=${accountId}`,
            {
                method: HttpMethod.DELETE,
                headers: JsonHeader
            }
        ));
        return await handleResponse(responseData);
    },
    
    async disposableManualDD(
        accountId: number,
        amount: number): Promise<ServiceResponse<boolean> | null> {

        if (!accountId || amount <= 0) {
            return null;
        }


        let responseData = fetch(new RequestSameOrigin(
            `/api/directdebit/manualdirectdebit?accountId=${accountId}&amount=${amount}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify({ amount })
            }
        ));

        return await handleResponse(responseData);
    },

    async directPayment(
        accountId: number,
        dpmDto: DirectPayment): Promise<ServiceResponse<boolean> | null> {

        if (!accountId) {
            return null;
        }

        let responseData = fetch(new RequestSameOrigin(
            `/api/directdebit/pay?accountId=${accountId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(
                    {
                        ...dpmDto,
                        CardExp: dpmDto.CardExp.format(expDateFormat)
                    })
            }
        ));

        return await handleResponse(responseData);
    },

    async linkAccount(params: LinkAccount): Promise<ServiceResponse<boolean> | null> {
        let responseData = fetch(new RequestSameOrigin(
            `/api/account/linkaccount`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(params)
            }
        ));

        return await handleResponse(responseData);
    },


    async defaultDirectDebitAmount(
        accountId: number): Promise<ServiceResponse<number> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/directdebit/defaultdirectdebitamount?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));
        return await handleResponse(responseData);
    },

    async getDirectDebitInfo(
        accountId: number): Promise<ServiceResponse<DirectDebitInfo | null> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/directdebit/directdebitinfo?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData);
    },

    async uploadSelfReadAsync(
        accountId: number,
        selfread: NewSelfReadUpload): Promise<ServiceResponse<boolean> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/site/selfread?accountId=${accountId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(selfread)
            }
        ));

        return await handleResponse(responseData, true, false);
    },

    async hasPendingSelfReadUploadAsync(
        accountServiceId: number): Promise<ServiceResponse<boolean> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/site/haspendingselfread?accountServiceId=${accountServiceId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData, true, false);
    },

    async accountHasPendingSelfReadUploadAsync(
        accountId: number): Promise<ServiceResponse<{ [key: number]: boolean }> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/site/accounthaspendingselfread?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData, true, false);
    },

    async getSelfReadsAsync(
        accountId: number,
        offset: number = 0,
        limit: number = 20): Promise<ServiceResponse<SelfReadUploadReview[]> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/site/selfread?accountId=${accountId}&offset=${offset}&limit=${limit}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData, false, false);
    },

    async getSelfReadCountAsync(
        accountId: number): Promise<ServiceResponse<number> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/site/selfreadcount?accountId=${accountId}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData, false, false);
    },

    async cancelSelfReadAsync(
        accountServiceId: number): Promise<ServiceResponse<boolean> | null> {

        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/site/cancelselfread?accountServiceId=${accountServiceId}`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader
            })));
    },

    async getImpersonateTokenByCodeAsync(
        impersonateTokenCode: string): Promise<ServiceResponse<string> | null> {

        var responseData = fetch(new RequestSameOrigin(
            `/api/account/impersonatetokenbycode?impersonateTokenCode=${impersonateTokenCode}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData, false, false);
    },

    async getAccountServiceStatusDateAsync(
        accountServiceIds: number[]): Promise<ServiceResponse<Record<number, AccountServiceStatusDateDto>> | null> {

        if (!accountServiceIds.length) {
            return new ServiceResponse(true, '', {});
        }

        const cachedResult: Record<number, AccountServiceStatusDateDto> = {};
        const missingAccountServiceIds: number[] = [];

        accountServiceIds.forEach(sId => {
            const cache = (accountServiceStatusDateCache[sId]
                ??= StaticDataCache.Create<AccountServiceStatusDateDto>(cacheTimeOut));

            const data = cache.data;

            if (!data) {
                missingAccountServiceIds.push(sId);
            }
            else {
                cachedResult[sId] = data;
            }
        });

        if (missingAccountServiceIds.length > 0) {

            const responseData = await handleResponse<Record<string, AccountServiceStatusDateDto>>(
                fetch(new RequestSameOrigin(
                    `/api/site/accountservicestatus?${queryString.stringify({ accountServiceIds: missingAccountServiceIds })}`,
                    {
                        method: HttpMethod.GET,
                        headers: JsonHeader
                    })));

            if (!responseData?.data) {
                return responseData;
            }

            for (const key in responseData.data) {

                const sId = parseInt(key);

                let momentParsedRes: any = {};

                for (const rawK in responseData.data[key]) {

                    const dtoKey = rawK as keyof AccountServiceStatusDateDto;

                    if (dtoKey.indexOf('Date') >= 0
                        && responseData.data[key][dtoKey]) { // can be null

                        momentParsedRes[dtoKey] = moment.utc(responseData.data[key][dtoKey]);
                    }
                    else {
                        momentParsedRes[dtoKey] = responseData.data[key][dtoKey];
                    }
                }

                accountServiceStatusDateCache[sId].data = momentParsedRes;

                cachedResult[sId] = momentParsedRes;
            }
        }

        return new ServiceResponse(true, '', cachedResult);
    },

    async getAccountMetersAsync(
        accountId: number,
        includeNonSwitchedAccountServices: boolean = false,
        includeRemovedMeters: boolean = false): Promise<ServiceResponse<AccountMeterDto[]> | null> {

        if (!accountId) {
            return null;
        }

        const cache = (accountMeterCache[`${accountId}|${includeNonSwitchedAccountServices}|${includeRemovedMeters}`]
            ??= StaticDataCache.Create<AccountMeterDto[]>(cacheTimeOut));

        if (cache.data) {
            return new ServiceResponse(true, '', cache.data);
        }

        const query = queryString.stringify({
            accountId,
            includeNonSwitchedAccountServices,
            includeRemovedMeters
        });

        const responseData = await handleResponse<AccountMeterDto[]>(
            fetch(new RequestSameOrigin(
                `/api/site/accountmeter?${query}`,
                {
                    method: HttpMethod.GET,
                    headers: JsonHeader
                })));

        if (responseData?.data) {
            cache.data = responseData.data;
        }

        return responseData;
    },

    async getDetailsForMoveOutAsync(
        accountServiceIds: number[]): Promise<ServiceResponse<Map<number, AccountServiceMoveOutDetailDto>> | null> {

        if (!accountServiceIds.length) {
            return new ServiceResponse(true, '', new Map());
        }

        const cachedResult: Map<number, AccountServiceMoveOutDetailDto> = new Map();
        const missingAccountServiceIds: number[] = [];

        accountServiceIds.forEach(sId => {
            const cache = (moveOutDetailsCache[sId]
                ??= StaticDataCache.Create<AccountServiceMoveOutDetailDto>(cacheTimeOut));

            const data = cache.data;

            if (!data) {
                missingAccountServiceIds.push(sId);
            }
            else {
                cachedResult.set(sId, data);
            }
        });

        if (missingAccountServiceIds.length > 0) {

            const responseData = await handleResponse<Record<string, AccountServiceMoveOutDetailDto>>(
                fetch(new RequestSameOrigin(
                    `/api/site/accountservicesformoveout?${queryString.stringify({ accountServiceIds: missingAccountServiceIds })}`,
                    {
                        method: HttpMethod.GET,
                        headers: JsonHeader
                    })));

            if (!responseData?.data) {
                return new ServiceResponse(false, '', new Map());
            }

            for (const key in responseData.data) {
                const sId = parseInt(key)

                moveOutDetailsCache[sId].data =
                {
                    ...responseData.data[key]
                };

                cachedResult.set(sId, moveOutDetailsCache[sId].data!);
            }
        }
        return new ServiceResponse(true, '', cachedResult);
    },

    async getAccountOwners(
        accountId: number): Promise<ServiceResponse<AccountOwnerInfoDto[]>> {

        if (!accountId) {
            return new ServiceResponse(true, '', new Array<AccountOwnerInfoDto>());
        }

        const cache = accountOwnersCache[accountId] ??= StaticDataCache.Create<AccountOwnerInfoDto[]>(cacheTimeOut);

        if (cache.data) {
            return new ServiceResponse(true, '', cache.data);
        }

        const responseData = await handleResponse<AccountOwnerInfoDto[]>(
            fetch(new RequestSameOrigin(
                `/api/account/getaccountowners?accountId=${accountId}`,
                {
                    method: HttpMethod.GET,
                    headers: JsonHeader
                })));

        if (!responseData?.data) {
            return new ServiceResponse(false, '', new Array<AccountOwnerInfoDto>());
        }

        accountOwnersCache[accountId].data = responseData.data;

        return responseData;

    },

    async getConcessionCardTypes(stateCode: string, fuelType: FuelType ): Promise<ServiceResponse<ConcessionCardTypeDto[]>>{     

        const cacheKey = `${stateCode}_${fuelType.toString()}`
        const cache = concessionCardTypeCache[cacheKey] ??= StaticDataCache.Create<ConcessionCardTypeDto[]>(cacheTimeOut);

        if (cache.data) {
            return new ServiceResponse(true, '', cache.data);
        }

        const responseData = await handleResponse<ConcessionCardTypeDto[]>(
            fetch(new RequestSameOrigin(
                `/api/concession/getconcessioncardtypes?stateCode=${stateCode}&fuelType=${fuelType}`,
                {
                    method: HttpMethod.GET,
                    headers: JsonHeader
                })));

        if (!responseData?.data) {
            return new ServiceResponse(false, '', new Array<ConcessionCardTypeDto>());
        }

        concessionCardTypeCache[cacheKey].data = responseData.data;

        return responseData;
    },

    async submitConcessionDetails(
        accountId: number,
        formInput: ConcessionFormInputDto): Promise<ServiceResponse<AddConcessionCardResultDto>> {

        const responseData = await handleResponse<AddConcessionCardResultDto>(
            fetch(new RequestSameOrigin(
                `/api/concession/submitConcessionDetails?accountId=${accountId}`,
                {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
                    body: JSON.stringify(formInput)
                })));

        if (!responseData?.data) {
            const failedDto = {
                addConcessionCardResult: AddConcessionCardResult.Failed
            } as AddConcessionCardResultDto
            
            return new ServiceResponse(false, '', failedDto);
        }
        //Clear cache for concession history after successful submission
        accountConcessionCardsCache[accountId] = StaticDataCache.Create<ConcessionCardInfoHistoryLiteDto[]>(cacheTimeOut);
        
        return new ServiceResponse<AddConcessionCardResultDto>(true, '', responseData.data);
    },

    async getConcessionCardsForAccount(
        accountId: number,
        offset: number,
        limit: number): Promise<ServiceResponse<PageResponse<ConcessionCardInfoHistoryLiteDto>>> {

        const cache = accountConcessionCardsCache[accountId] ??= StaticDataCache.Create<ConcessionCardInfoHistoryLiteDto[]>(cacheTimeOut);

        let responseData: ConcessionCardInfoHistoryLiteDto[] | null = null;

        if (cache.data) {
            responseData = cache.data;
        } else {
            var response = await handleResponse<ConcessionCardInfoHistoryLiteDto[]>(
                fetch(new RequestSameOrigin(
                    `/api/concession/getConcessionCardsForAccount?accountId=${accountId}`,
                    {
                        method: HttpMethod.GET,
                        headers: JsonHeader
                    })));
            if (!response?.data) {
                return new ServiceResponse(false, '');
            }
            responseData = response.data.sort((a, b) => {
                if (a.startDate === b.startDate) {
                    return b.concessionCardInfoHistoryId < a.concessionCardInfoHistoryId ? -1 : 1
                }
                return b.startDate < a.startDate ? -1 : 1
            });
        }

        var res = new PageResponse<ConcessionCardInfoHistoryLiteDto>();
        res.data = responseData.slice(offset, offset + limit);
        res.totalCount = responseData.length;
        return new ServiceResponse(true, '', res);
    },

    async uploadAndParseMeterReadPhotoAsync(
        accountId: Number,
        file: File,
        skipParse: boolean = false) {

        if (!accountId || !file) {
            return;
        }

        const bodyFormData = new FormData();
        bodyFormData.append(file.name, file);

        return await handleResponse<UploadParseMeterPhotoResultDto>(
            fetch(new RequestSameOrigin(
                `/api/site/uploadandparsemeterphoto?accountId=${accountId}`
                + (skipParse ? '&skipParse=true' : ''),
                {
                    method: HttpMethod.POST,
                    body: bodyFormData
                })),
            false);
    },

    async getReconnectionInfoAsync(
        accountServiceIds: number[]): Promise<ServiceResponse<ReconnectionInfo> | null> {
        delay(1000);

        if (!accountServiceIds.length) {
            return null;
        }

        var responseData = fetch(new RequestSameOrigin(
            `/api/site/poweraccountservicesforreen?${queryString.stringify({ accountServiceIds: accountServiceIds })}`,
            {
                method: HttpMethod.GET,
                headers: JsonHeader
            }
        ));

        return await handleResponse(responseData);
    },

    async submitReconnectionAsync(
        reconnectionInput: ReconnectionRequestDto): Promise<ServiceResponse<boolean> | null> {

        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/site/reconnection`,
            {
                method: HttpMethod.POST,
                headers: JsonHeader,
                body: JSON.stringify(reconnectionInput)
            })));
    },

    async getLatestBasicMeterReadsForAccount(
        accountId: number): Promise<ServiceResponse<BasicMeterRead[]> | null> {
        
        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/site/getLatestBasicMeterReadsForAccount?accountId=${accountId}`)));
    },

    async lookupReferralLink(accountId: number): Promise<ServiceResponse<LookupReferralLinkDto>| null> {
        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/referral/lookupReferralLink?accountId=${accountId}`,
            {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
            })));
    },

    async getSignupInfo(accountId: number): Promise<ServiceResponse<SignupInfoDto[]>| null> {
        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/account/getSignupInfo?accountId=${accountId}`,
            {
                    method: HttpMethod.GET,
                    headers: JsonHeader,
            })));
    },

    async getContactsForCallback(accountId: number): Promise<ServiceResponse<CallbackContactDto[]>| null> {
        const cachedResponse = callbackContactsCache[accountId] ??= StaticDataCache.Create<CallbackContactDto[]>(cacheTimeOut);

        if (cachedResponse.data) {
            return new ServiceResponse(true, '', cachedResponse.data);
        }

        var apiResponse = await handleResponse<CallbackContactDto[]>(fetch(new RequestSameOrigin(
            `/api/account/getContactsForCallback?accountId=${accountId}`,
            {
                    method: HttpMethod.GET,
                    headers: JsonHeader,
            })));
        
        if (apiResponse?.success && apiResponse?.data) {
            callbackContactsCache[accountId].data = apiResponse.data;
        }

        return apiResponse;
    },
    
    async lodgeCallback(accountId: number, LodgeCallbackRequest: LodgeCallbackRequest) : Promise<ServiceResponse<boolean> | null> {
        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/callback/lodgeCallbackRequest?accountId=${accountId}`,
            {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
                    body: JSON.stringify(LodgeCallbackRequest)
            })));
    },

    async getCallbackTimeSlotInfo(targetDate: Moment) : Promise<ServiceResponse<Map<number, boolean>> | null> {
        const date = moment(targetDate).format(DateTimeFormat);
        const apiResponse = fetch(new RequestSameOrigin(
            `/api/callback/getCallbackTimeSlotInfo?targetDate=${date}`,
            {
                    method: HttpMethod.GET,
                    headers: JsonHeader,
            }));
        
        const data = await handleResponse<Record<number, boolean>>(apiResponse);
        const result = new ServiceResponse<Map<number, boolean>>();

        if (data?.success && data?.data) {
            const map = new Map<number, boolean>();

            for (var k in data.data) {
                map.set(parseInt(k), data.data[k]);
            }

            result.success = true;
            result.data = map;
        }

        return result;
    },

    async getCallbackCategories() : Promise<ServiceResponse<CallbackCategory[]> | null> {
        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/callback/getCallbackCategories`,
            {
                    method: HttpMethod.GET,
                    headers: JsonHeader,
            })));
    },

    async getExistingCallbacksForAccount(accountId: number) : Promise<ServiceResponse<CallbackInfo[]> | null> {
        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/callback/getExistingCallbacksForAccount?accountId=${accountId}`,
            {
                    method: HttpMethod.GET,
                    headers: JsonHeader,
            })));
    },

    async ignoreCallback(accountId: number, phoneNumber: string) : Promise<ServiceResponse<boolean> | null> {
        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/callback/ignoreCallback?accountId=${accountId}&phoneNumber=${phoneNumber}`,
            {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
            })));
    },

    async updateScheduledDateTime(accountId: number, phoneNumber: string, newScheduledDateTime: string) : Promise<ServiceResponse<boolean> | null> {
        return await handleResponse(fetch(new RequestSameOrigin(
            `/api/callback/updateScheduledDateTime?accountId=${accountId}&phoneNumber=${phoneNumber}&newScheduledDateTime=${newScheduledDateTime}`,
            {
                    method: HttpMethod.POST,
                    headers: JsonHeader,
            })));
    }

}

export default requestDataService;

export const {
    setupDirectDebit,
    getAccountBalance,
    getAccountPaymentBtnFlag,
    disposableManualDD,
    directPayment,
    getAccountActivateMeta,
    defaultDirectDebitAmount,
    linkAccount,
    getDirectDebitInfo,
    deleteDirectDebit,
    getAccountInfo,
    updateAccountInfo,
    sendCode,
    verifyCode,
    getAuthorizedContacts,
    upsertAuthorizedContact,
    deleteAuthorizedContact,
    getSiteMetersAsync,
    uploadSelfReadAsync,
    hasPendingSelfReadUploadAsync,
    getSelfReadsAsync,
    getSelfReadCountAsync,
    getImpersonateTokenByCodeAsync,
    getAccountServiceStatusDateAsync,
    getMeterListFromRead,
    downLoadCsvFile,
    getBasicMeterRead,
    getSmartMeterRead,
    getAccountInvoicesAsync,
    getCorrespondenceDetailAsync,
    submitMoveOutAsync,
    getDetailsForMoveOutAsync,
    getPublicHolidays,
    getAccountOwners,
    getConcessionCardTypes,
    submitConcessionDetails,
    getConcessionCardsForAccount,
    cancelSelfReadAsync,
    getAccountMetersAsync,
    uploadAndParseMeterReadPhotoAsync,
    accountHasPendingSelfReadUploadAsync,
    getReconnectionInfoAsync,
    submitReconnectionAsync,
    getLatestBasicMeterReadsForAccount,
    getPowerMeterTypes,
    lookupReferralLink,
    getJointOwners,
    updateJointOwner,
    sendEmailVerificationCode,
    getContactsForCallback,
    getCallbackCategories,
    lodgeCallback,
    getCallbackTimeSlotInfo,
    getExistingCallbacksForAccount,
    ignoreCallback,
    updateScheduledDateTime
} = requestDataService;